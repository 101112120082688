<template>
  <div>
    <div ref="paypalWrapper"></div>
  </div>
</template>

<script>

import { loadScript } from '@paypal/paypal-js'

export default {
  name: 'PayPalButton',
  props: ['order'],
  data () {
    return {}
  },
  created () {
  },
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      await this.loadPaypalScript()
    },
    /**
     * Carrega o script
    */
    async loadPaypalScript () {
      let paypal
      try {
        paypal = await loadScript({
          clientId: process.env.VUE_APP_PAYPAL_CLIENT_ID,
          currency: this.order.advertiser.currency,
          'disable-funding': 'card'
        })
      } catch (error) {
        console.error('failed to load the PayPal JS SDK script', error)
      }
      if (paypal) {
        try {
          await paypal.Buttons({
            createOrder: (data, actions) => {
              return actions.order.create({
                purchase_units: [
                  {
                    amount: {
                      currency_code: this.order.advertiser.currency,
                      value: this.order.amount / 100 // Set the total amount for the order
                    }
                  }
                ]
              })
            },
            onApprove: async (data, actions) => {
              const order = await actions.order.get()
              this.$emit('approved', order)
            },
            onError: (err) => {
              this.$emit('error', err)
              console.error('PayPal error: ', err)
              // Handle errors here
            }
          }).render(this.$refs.paypalWrapper)
        } catch (error) {
          this.$emit('error', error)
          console.error('failed to render the PayPal Buttons', error)
        }
      }
    }
  }
}

</script>
